import api from "./api";

const findTrainingsRequest = (params) => {
  return api.get(`api/treinamentos/`);
};

const findAllTrainingsAndCourses = (params, filter) => {
  return api.get("api/treinamentosCursos", filter);
};

const findTrainingRequest = (a, id) => {
  return api.get(`api/treinamentos/${id}`);
};

const findTrainingByIdRequest = (id) => {
  return api.get(`api/treinamentos/${id}`);
};

const findUsersTrainingById = (id) => {
  return api.get(`api/buscaTodosUsuariosTreinamento/${id}`);
};

const completeUsersTraining = (param, body) => {
  return api.post(`api/treinamentoConcluidoByUserId`, body);
};

const findTrainingsByTopicRequest = (a, params) => {
  return api.get(`api/treinamentos/findByTopic/${params}`);
};

const findTrainingsByTopicActiveRequest = (topic_id) => {
  return api.get(`api/treinamentos/findByTopicActive/${topic_id}`);
};

const deleteTrainingRequest = (param, id) => {
  return api.delete(`api/treinamentos/${id}`);
};

const createTrainingRequest = (param, training) => {
  return api.post('api/treinamentos', training);
};

const updateTrainingRequest = (param, training) => {
  return api.put(`api/treinamentos/${training.id}`, training);
};

export {
  findTrainingRequest,
  findTrainingsRequest,
  completeUsersTraining,
  findUsersTrainingById,
  deleteTrainingRequest,
  createTrainingRequest,
  updateTrainingRequest,
  findTrainingByIdRequest,
  findAllTrainingsAndCourses,
  findTrainingsByTopicRequest,
  findTrainingsByTopicActiveRequest,
};
