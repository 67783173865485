import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Formik } from 'formik';
import { Button, Form, Tabs } from 'antd';
import { Col, FormButtons, FormDropdown, FormInputDate, FormInputText, FormTextArea, Row } from 'components';
import { CourseValidationSchema } from './ValidationSchema';
import { TabPaneStyled, TabsStyled } from './styles';
import useFetch from '../../../../hooks/useFetch';
import {Dropdown, FormCheckbox} from '../../../../common';
import {
  createTopicRequest,
  deleteTopicRequest,
  findTopicsRequest, findTopicWithTrainingsRequest,
  updateTopicRequest,
} from '../../../../services/topics';
import Crud from '../components/Crud';
import { updateCourseRequest } from '../../../../services/course';
import TopicForm from '../../Topics/Form';
import OutCrud from '../components/OutCrud';
import { createTrainingRequest, deleteTrainingRequest, updateTrainingRequest } from '../../../../services/training';
import { Colors, Metrics } from '../../../../configs';
import { ArrowRightOutlined } from '@ant-design/icons';
import QuestionForm from '../../Question/Form';
import { Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const {TabPane} = Tabs;

const CourseForm = ({  initialValues, handleSubmitForm, handleCancel, tabIndex, setTabIndex }) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmitForm}>
      {({ handleSubmit, values }) => (
        <Form>
          <FormInputText
            name="ds_titulo"
            label="Título"
            required
            value={values.ds_titulo}
          />
          <FormTextArea
            label="Descrição"
            name="ds_curso"
            required
            value={values.ds_curso}
          />
          <FormInputText
            label="Resumo"
            name="ds_resumo"
            required
            value={values.ds_resumo}
          />
          <FormInputText
            label="Tempo de acesso"
            name="ds_tempo_acesso"
            value={values.ds_tempo_acesso}
          />
          <FormInputText
            label="Horas de video"
            name="ds_hrs_video"
            value={values.ds_hrs_video}
          />
          <FormInputText
            type='number'
            label="Porcentagem de desconto"
            name="pr_desconto"
            value={values.pr_desconto}
          />
          <FormInputDate
            label="Data validade desconto"
            name="dt_validade_desconto"
            value={values.dt_validade_desconto}
          />
          <FormInputText
            type='number'
            label="Valor"
            name="vl_curso"
            required
            value={values.vl_curso}
          />
          <Col style={{marginBottom: Metrics.spacingMD}}>
            <span>Formas de pagamento</span>
            <Row>
              <Row style={{width: '130px', alignItems: 'center'}}>
                <FormCheckbox
                  label='Boleto'
                  name='fg_boleto'
                  value={values.fg_boleto}
                />
              </Row>
              {values.fg_boleto &&
                <FormInputText
                  type='number'
                  label="Quantidade de parcelas"
                  name="nu_parcelas_boleto"
                  value={values.nu_parcelas_boleto}
                />
              }
            </Row>
            <Row>
              <Row style={{width: '130px', alignItems: 'center'}}>
                <FormCheckbox
                  label='Cartão'
                  name='fg_cartao'
                  value={values.fg_cartao}
                />
              </Row>
              {values.fg_cartao &&
                <FormInputText
                  type='number'
                  label="Quantidade de parcelas"
                  name="nu_parcelas_cartao"
                  value={values.nu_parcelas_cartao}
                />
              }
            </Row>
            <Row>
              <Row style={{width: '200px', alignItems: 'center'}}>
                <FormCheckbox
                  label='Possui certificado ?'
                  name='has_certificate'
                  value={values.has_certificate}
                />
              </Row>

              <Row style={{width: '200px', alignItems: 'center'}}>
                <FormCheckbox
                  label='Curso por módulo'
                  name='has_module'
                  value={values.has_module}
                />
              </Row>
            </Row>
            <Row>
              <Row style={{width: '130px', alignItems: 'center'}}>
                <FormCheckbox
                  label='Pix'
                  name='fg_pix'
                  value={values.fg_pix}
                />
              </Row>
              {values.fg_pix &&
                <FormInputText
                  type='number'
                  label="Quantidade de parcelas"
                  name="nu_parcelas_pix"
                  value={values.nu_parcelas_pix}
                />
              }
            </Row>
          </Col>
          <FormInputDate
            label="Início"
            name="dt_inicio"
            required
            value={values.dt_inicio}
          />
          <FormInputDate
            label="Fim"
            name="dt_fim"
            required
            value={values.dt_fim}
          />
          <FormInputText
            label="Url da imagem"
            name="ds_imagem"
            required
            value={values.ds_imagem}
          />
          <FormButtons
            bt2={{ label: 'Salvar', onClick: handleSubmit}}
          />
        </Form>
      )}
    </Formik>
  );
};

export default CourseForm;
