import { useState } from "react";

import { Formik } from "formik";
import { Button } from "common";
import { Form, Typography } from "antd";
import { Colors, Metrics } from "configs";
import Input from "common/FormInputCustom";
import {UnlockOutlined} from "@ant-design/icons";
import PageTemplate from "template/PageTemplate";
import {useParams, useHistory} from "react-router-dom";

import {Toast} from "../../utils";

import { RecoverPassword } from "./ValidationSchema";
import { recoverPasswordAPI } from "services/auth";
const { Text } = Typography;

export const RecoverPasswordWithHash = () => {
  const { hash } = useParams();
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const recoverPassword = async ({password}) => {
    try {
      setLoading(true);

      const payload = {
        hash,
        password,
      };

      await recoverPasswordAPI(payload);

      Toast.showSuccessMessage('Senha alterada com sucesso!');
      history.push(`/login`)
    } catch (error) {
      Toast.showErrorMessage("Erro ao resetar sua senha! Tente novamente.");
    } finally {
      setLoading(false);
    }
  }

  return (
    <PageTemplate contentStyle={styles.container}>
      <Formik
        initialValues={{
          email:"",
          nuCpf:"",
          password: "",
          confirmPassword: "",
        }}
        onSubmit={recoverPassword}
        validationSchema={RecoverPassword}
      >
        {({ values, handleSubmit }) => (
          <Form style={styles.form}>
            <div style={styles.wrapper}>
              <Text
                strong
                style={{ color: Colors.primary, fontSize: Metrics.fontSize.sm }}
              >
                Alterar sua senha
              </Text>
              <div style={styles.inputContainer}>

                <Input
                  placeholder="Senha"
                  prefix={<UnlockOutlined />}
                  password
                  name="password"
                  value={values.password}
                />
                <Input
                  placeholder="Confirme sua senha"
                  prefix={<UnlockOutlined />}
                  password
                  name="confirmPassword"
                  value={values.confirmPassword}
                />
              </div>
              <Button width="200px" type="submit" onClick={handleSubmit} disabled={loading} loading={loading}>
                Alterar
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </PageTemplate>
  );
};

const styles = {
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  wrapper: {
    display: "inline-flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: Colors.white,
    padding: "40px 60px",
    borderRadius: "20px",
    boxShadow: "0px 1px 6px #0000001A",
    minWidth: "30%",
  },
  form: {
    display: "flex",
    justifyContent: "center",
  },
  inputContainer: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    marginTop: Metrics.spacing.xlg,
    marginBottom: Metrics.spacing.xlg,
    width: "100%",
  },
};
