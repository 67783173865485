import React, { useContext, useEffect, useState } from 'react';
import { Metrics } from '../../../../../configs';
import { CourseWizardContext } from '../index';

import useFetch from '../../../../../hooks/useFetch';
import {
  createTopicRequest,
  deleteTopicRequest,
  findTopicRequest,
  findTopicsByCourseRequest,
} from '../../../../../services/topics';
import { CheckCircleOutlined, CloseOutlined } from '@ant-design/icons';
import { Dropdown, Spin, Table, Collapse } from 'antd';
import TableMenu from '../../components/TableMenu';
import { AiOutlineMore } from 'react-icons/ai';
import TopicForm from '../../../Topics/Form';
import { Select } from 'antd';
import {
  createTrainingRequest,
  deleteTrainingRequest, findTrainingRequest,
  findTrainingsByTopicRequest,
} from '../../../../../services/training';
import { Col } from '../../../../../common';
import QuestionForm from '../../../Question/Form';
const { Panel } = Collapse;

const { Option } = Select;
const styles = {
  formContainer: {
    backgroundColor: 'white',
    padding: Metrics.spacing.md,
    borderRadius: Metrics.defaultRadius,
    marginTop: Metrics.spacing.md}
}

const AlternativeData = () => {
  const context = useContext(CourseWizardContext);
  return (
    <div style={styles.formContainer}>
      <h1>Question data</h1>
      <QuestionForm handleSubmitForm={() => {}} initialValues={{}} handleCancel={() => {}} />
    </div>
  );
};

export default AlternativeData;
