import { useState } from "react";

import { Formik } from "formik";
import { Button } from "common";
import { Form, Typography } from "antd";
import { Colors, Metrics } from "configs";
import Input from "common/FormInputCustom";
import {useHistory} from "react-router-dom";
import {MailOutlined} from "@ant-design/icons";

import {Toast} from "../../utils";

import PageTemplate from "template/PageTemplate";
import { resetPasswordAPI } from "services/auth";
import { ChangePasswordEmailCpfValidationSchema } from "./ValidationSchema";
const { Text } = Typography;

const ChangePasswordEmailCpf = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const resetPassword = async (data) => {
    try {
      setLoading(true);
      await resetPasswordAPI({email: data.email});

      Toast.showSuccessMessage('Solicitação enviada para o email cadastrado!');

      history.push(`/login`)
    } catch (error) {
      Toast.showErrorMessage("Erro ao resetar sua senha! Tente novamente.");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <PageTemplate contentStyle={styles.container}>
      <Formik
        initialValues={{
          email:"",
        }}
        onSubmit={resetPassword}
        validationSchema={ChangePasswordEmailCpfValidationSchema}
      >
        {({ values, handleSubmit }) => (
          <Form style={styles.form}>
            <div style={styles.wrapper}>
              <Text
                strong
                style={{ color: Colors.primary, fontSize: Metrics.fontSize.sm }}
              >
                Alterar sua senha
              </Text>
              <div style={styles.inputContainer}>
                <Input
                  placeholder="Digite Seu E-mail de Cadastro"
                  prefix={<MailOutlined />}
                  name="email"
                  value={values.email}
                />
              </div>
              <Button width="200px" type="submit" onClick={handleSubmit} disabled={loading} loading={loading}>
                Alterar
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </PageTemplate>
  );
};

const styles = {
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  wrapper: {
    display: "inline-flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: Colors.white,
    padding: "40px 60px",
    borderRadius: "20px",
    boxShadow: "0px 1px 6px #0000001A",
    minWidth: "30%",
  },
  form: {
    display: "flex",
    justifyContent: "center",
  },
  inputContainer: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    marginTop: Metrics.spacing.xlg,
    marginBottom: Metrics.spacing.xlg,
    width: "100%",
  },
};

export default ChangePasswordEmailCpf;
