import React, { useCallback, useEffect, useState } from "react";

import { Formik, useFormik } from "formik";
import { Button, Form, Tabs, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { Col, FormButtons, FormDropdown, FormInputDate, FormInputText, FormTextArea, Whitespace } from "components";

import useFetch from "../../../../hooks/useFetch";
import { FormCheckbox } from "../../../../common";

import { Toast } from "../../../../utils";
import { Metrics } from "../../../../configs";
import { saveVideoToTraining } from "../../../../services/course";
import { findQuestionsRequest } from "../../../../services/question";
import { findTrainingsByTopicActiveRequest } from "../../../../services/training";

const { TabPane } = Tabs;

const TrainingForm = ({ handleSubmitForm, initialValues, handleCancel }) => {
  const [loading, setLoading] = useState();
  const [uploading, setUploading] = useState();
  const [fileList, setFileList] = useState([]);
  const [trainings, setTrainings] = useState([]);

  const formik = useFormik({
    enableReinitialize: true,
    onSubmit: handleSubmitForm,
    initialValues: initialValues,
  });

  const [{ isFetching, data: questions }] = useFetch(findQuestionsRequest, "", true, null);
  const [{ isFetching: isFetchingUpload, data: trainingSaved }, saveReq] = useFetch(saveVideoToTraining, "", false, null, null, (res) => {
    setUploading(false);
    if (res?.data?.urlFile) {
      formik.setFieldValue("ds_url_video", res?.data?.urlFile);
    }
    Toast.showSuccessMessage("Arquivo enviado com sucesso!");
    return res.data;
  });
  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList((fl) => [...fl, file]);
      return false;
    },
    fileList,
  };

  const handleUpload = useCallback(async () => {
    await saveReq({ trainingId: initialValues.id, file: fileList[0] });
  }, [fileList, initialValues]);

  const getTrainingsByTopicId = async () => {
    try {
      setLoading(true);
      const { data } = await findTrainingsByTopicActiveRequest(initialValues.topico_id);
      setTrainings(data);
    } catch (error) {
      console.log(56, error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTrainingsByTopicId();
  }, [initialValues]);

  return (
    <Formik enableReinitialize initialValues={formik.initialValues} onSubmit={formik.handleSubmit}>
      {({ handleSubmit, values, resetForm, initialValues }) => (
        <Form>
          {initialValues.id && (
            <Col style={{ backgroundColor: "#F0F2F5", padding: Metrics.spacingMD, marginBottom: Metrics.spacingMD }}>
              <span>
                <b>Carregar mídia do treinamento</b>
              </span>
              <Col style={{ justifyContent: "center", alignItems: "center" }}>
                <Upload {...props}>
                  <Button icon={<UploadOutlined />}>Selecionar arquivo</Button>
                </Upload>
                <Button onClick={handleUpload} disabled={fileList.length === 0 || isFetchingUpload} loading={isFetchingUpload} style={{ marginTop: 16 }}>
                  {isFetchingUpload ? "Carregando..." : "Carregar"}
                </Button>
              </Col>
            </Col>
          )}
          <FormInputText name="ds_titulo" label="Título" required value={formik.values.ds_titulo} />
          <FormTextArea label="Resumo" name="ds_resumo" required value={formik.values.ds_resumo} />
          <FormInputText name="ds_url_video" label="Url do Vídeo" required value={formik.values.ds_url_video} />

          <FormDropdown list={trainings} propertyValue="id" disabled={loading} name="precedente_id" propertyLabel="ds_titulo" value={formik.values.precedente_id} label="Treinamento precedente" placeholder="Selecione o treinamento que é pré-requisito deste" />

          <Whitespace height={Metrics.spacing.lg} />
          <FormInputText type="number" label="Porcentagem de acerto do precedente" name="pr_acerto_precedente" value={formik.values.pr_acerto_precedente} />
          <FormInputDate label="Data início" name="dt_inicio" value={formik.values.dt_inicio} />
          <FormInputDate label="Data fim" name="dt_fim" value={formik.values.dt_fim} />
          <FormCheckbox label="Aula presencial" name="is_in_person" value={formik.values.is_in_person} />
          <FormButtons
            bt2={{
              label: "Salvar",
              onClick: () => {
                handleSubmit(formik.values);
                setTimeout(() => resetForm(), 0);
              },
            }}
          />
        </Form>
      )}
    </Formik>
  );
};

export default TrainingForm;
