import { useFormikContext } from "formik";
import { FormItem } from "formik-antd";
import { Select } from "./styles";
// import Picker from '../Picker';

const FormDropdown = ({
  label,
  name,
  value,
  placeholder,
  required,
  multiple,
  showSearch,
  filterOption,
  propertyLabel = "label",
  propertyValue = "value",
  list = [],
  disabled,
  showValidateSuccess,
  onChange: onChangeParam,
}) => {
  const { getFieldMeta } = useFormikContext();
  const { error, touched } = getFieldMeta(name);

  const onChange = (newValue) => {
    if (!disabled && onChangeParam) {
      onChangeParam(newValue);
    }
  };

  return (
    <FormItem
      style={{ width: "100%" }}
      name={name}
      required={required}
      showValidateSuccess={showValidateSuccess}
    >
      {label && (
        <span style={{ color: error && touched && "#ff4d4f" }}>
          {required ? `${label} *` : label}
        </span>
      )}
      <Select
        allowClear
        name={name}
        value={value}
        onChange={onChange}
        required={required}
        disabled={disabled}
        showSearch={showSearch}
        filterOption={filterOption}
        placeholder={required ? `${placeholder} *` : placeholder}
        {...(multiple ? { mode: "multiple" } : [])}
      >
        {list.map((item, index) => (
          <Select.Option key={index} value={item[propertyValue]}>
            {item[propertyLabel]}
          </Select.Option>
        ))}
      </Select>
    </FormItem>
  );
};

export default FormDropdown;
